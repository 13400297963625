import $ from "jquery";
import {startMaster, stopMaster} from "./stream-connection";


let ROLE = null; // Possible values: 'master', 'viewer', null

function configureLogging() {
    function log(level, messages) {
        const text = messages
            .map(message => {
                if (typeof message === 'object') {
                    return JSON.stringify(message, null, 2);
                } else {
                    return message;
                }
            })
            .join(' ');
        $('#logs').append($(`<div class="${level.toLowerCase()}">`).text(`[${new Date().toISOString()}] [${level}] ${text}\n`));
    }

    console._error = console.error;
    console.error = function (...rest) {
        log('ERROR', Array.prototype.slice.call(rest));
        console._error.apply(this, rest);
    };

    console._warn = console.warn;
    console.warn = function (...rest) {
        log('WARN', Array.prototype.slice.call(rest));
        console._warn.apply(this, rest);
    };

    console._log = console.log;
    console.log = function (...rest) {
        log('INFO', Array.prototype.slice.call(rest));
        console._log.apply(this, rest);
    };
}

function getRandomClientId() {
    return Math.random()
        .toString(36)
        .substring(2)
        .toUpperCase();
}

function onStatsReport(report) {
    // TODO: Publish stats
}

function onStop(event) {
    if (!ROLE) {
        return;
    }

    if (ROLE === 'master') {
        stopMaster(event);
    }

    ROLE = null;
    $('#master-button').removeClass('d-none');
    $('#stop-master-button').addClass('d-none');
}

window.addEventListener('beforeunload', async () => {
    const localView = $('.user-video .local-view')[0];
    if (localView) {
        onStop(localView.data('event'));
    }
});

window.addEventListener('error', function (event) {
    // console.error(event.message);
    event.preventDefault();
});

window.addEventListener('unhandledrejection', function (event) {
    event.preventDefault();
});

// configureLogging();

$('#master-button').on('click', () => {
    ROLE = 'master';

    const localView = $('.user-video .local-view')[0];
    const remoteView = $('.user-video .remote-view')[0];
    const localMessage = $('.user-video .local-message')[0];
    const remoteMessage = $('.user-video .remote-message')[0];

    // $(remoteMessage).empty();
    // localMessage.value = '';

    startMaster(localView, remoteView, onStatsReport, event => {
        remoteMessage.append(`${event.data}\n`);
    });

    $('#master-button').addClass('d-none');
    $('#stop-master-button').removeClass('d-none');
});

$('#stop-master-button').on('click', () => {
    const localView = $('.user-video .local-view')[0];
    console.log(localView);
    onStop($(localView).data('event'));
});
